import React, { useState } from "react";
import "./Login.css";
import PropTypes from "prop-types";
import axios from "axios";

async function loginUser(email, password) {
    try {
        console.log(email, password);
        const response = await axios.post(
            "https://devpipe.revolutiontravelcrm.com/api/v1/agent/login",
            {
                email,
                password,
            }
        );
        if (response.status === 200) {
            return response.data.token;
        }
        if (response.status === 403) {
            alert(JSON.stringify(response));
        }
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

export default function Login({ setToken }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await loginUser({
            email,
            password,
        });
        setToken(token);
    };

    return (
        <div className="login-wrapper">
            <h1>Please Log In</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Username</p>
                    <input
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </label>
                <label>
                    <p>Password</p>
                    <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <div>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};
